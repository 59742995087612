<template>
  <v-container class="persons_container mt-5">
    <div style="display: flex; margin-bottom: 5px">
      <h3>Involved Party(s)</h3>

      <v-tooltip top :disabled="$vuetify.breakpoint.xs">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            top
            style="top: -11px"
            color="secondary"
            class="black--text"
            icon
            large
            @click="addNewParty()"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon size="34">$vuetify.icons.plusCircle</v-icon>
          </v-btn>
        </template>
        <span>Add an Involved Party</span>
      </v-tooltip>
    </div>

    <v-data-iterator
      v-if="computed_party_list.length > 0"
      :items="computed_party_list"
      hide-default-footer
      disable-pagination
      class="white--text mb-2"
    >
      <template v-slot:default="props">
        <v-row>
          <v-card
            class="px-3 py-2 mx-6 mb-3"
            color="backdrop lighten-2"
            elevation="2"
            v-for="(p, index) in props.items"
            :key="index"
            style="width: 100%"
          >
            <v-row>
              <v-col cols="12" sm="11" md="11">
                <v-row dense cols="12">
                  <v-col cols="12" sm="3" md="3">
                    <v-text-field
                      v-model="p.firstname"
                      label="First Name"
                      maxlength="60"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3">
                    <v-text-field
                      v-model="p.lastname"
                      label="Last Name"
                      maxlength="60"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3">
                    <v-combobox
                      v-model="p.involvement"
                      :items="involvement_list"
                      @click:clear="onClearInvolvement(index)"
                      clearable
                      label="Involvement"
                      maxlength="60"
                    ></v-combobox>
                  </v-col>
                  <v-col cols="12" sm="3" md="3">
                    <v-text-field
                      v-model="p.dob"
                      type="date"
                      label="DOB"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="p.description"
                      label="Description"
                      maxlength="1000"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="1" md="1">
                <div style="text-align-last: center">
                  <v-tooltip top :disabled="$vuetify.breakpoint.xs">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        text
                        color="error"
                        :style="
                          $vuetify.breakpoint.xs
                            ? 'border-color: var(--v-error-lighten1);'
                            : ''
                        "
                        @click="removeParty(p)"
                        :block="$vuetify.breakpoint.xs"
                        :disabled="!canRemoveParty(p)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon size="30">$vuetify.icons.del</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete this party</span>
                  </v-tooltip>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-row>
      </template>
    </v-data-iterator>
  </v-container>
</template>

<style lang="scss" scoped>
.person_card {
  width: 100%;
}
.persons_container {
  padding: 0px !important;
}
</style>

<script>
/* eslint-disable */
import { bus_common } from "../../mixins/bus_common";
export default {
  name: "logs_parties",
  mixins: [bus_common],
  props: ["logEntryID", "party_list"],
  data() {
    return {
      involvement_list: [
        "ARR",
        "CIT",
        "LEP",
        "DET",
        "DRV",
        "SUS",
        "SUB",
        "VIC",
        "UNK",
        "MED",
        "INJ",
        "WIT",
        "OWN",
        "POW",
      ],
    };
  },
  methods: {
    message1() {
      alert("This button does nothing yet.");
    },
    addNewParty() {
      let party = {
        logPartyID: 0,
        logEntryID_logPartyID: this.logEntryID,
        firstname: "",
        lastname: "",
        involvement: "",
        dob: "",
        description: "",
        s_del: "f",
        s_user: this.$store.state.user.email,
        s_createduser: this.$store.state.user.email,
        s_createdate: "1900-01-01",
        s_date: "1900-01-01",
      };
      this.party_list.push(party);
    },
    removeParty(party) {
      for (let i = 0; i < this.party_list.length; i++) {
        if (this.party_list[i] === party) {
          if (party.logPartyID == 0) {
            this.party_list.splice(i, 1);
            return;
          }
          // If it's an old party, we can only set to s_del = "t"
          else {
            this.party_list[i].s_del = "t";
          }
        }
      }
    },
    canRemoveParty(party) {
      // // If it's a new party, we can always delete it
      // if (party.logPartyID == 0) {
      //   return true;
      // }
      // // If it's an old party, we can only delete it if the date is valid
      // // In order to prevent hidden invalid date records
      // else {
      //   if (this.date_is_valid(party.dob)) {
      //     return true;
      //   } else {
      //     return false;
      //   }
      // }
      return true;
    },

    onClearInvolvement(i) {
      setTimeout(() => {
        this.party_list[i].involvement = "";
      }, 100);
    },
    init() {
      this.involvement_list = this.involvement_list.sort();
      if (this.party_list.length == 0) {
        this.addNewParty();
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.init();
    });
  },
  computed: {
    computed_party_list: function () {
      return this.party_list.filter((i) => i.s_del === "f" || i.s_del === "d");
    },
  },
};
</script>
