import Vue from 'vue'
import App from './components/App.vue'
import router from './router'
import store from './store'
import VueSweetalert2 from 'vue-sweetalert2';
import moment from 'moment-timezone'
import VueTheMask from 'vue-the-mask'



////Use SignalRHub plugin (Starts connection) {{ENABLE SIGNALR}}
//import PhoneCenterHubPlugin from "./signalr/PhoneCenterHubPlugin"
//Vue.use(PhoneCenterHubPlugin);

Vue.config.productionTip = false

//Import Axios
import Axios from 'axios'
Vue.prototype.$axios = Axios;

//import SWAL 
import 'sweetalert2/dist/sweetalert2.min.css';
import '@sweetalert2/theme-dark/dark.css';
Vue.use(VueSweetalert2);

import vuetify from './plugins/vuetify'


//Import moment
moment.tz.setDefault("America/Los_Angeles");
Vue.prototype.$moment = moment;

moment.tz.setDefault("America/Los_Angeles");

//Import VueTheMask 
Vue.use(VueTheMask)


// Idle Vue plugin
// This adds lifecycle hooks onIdle and onActive to each component
import IdleVue from 'idle-vue'
const eventsHub = new Vue()
Vue.use(IdleVue, {
    eventEmitter: eventsHub,
    idleTime: 1800000 // 30m
})

//HTML to Paper
// Replace with correct css file when debugging './vic_print.css' './sus_print.css' './env_print.css'
import VueHtmlToPaper from 'vue-html-to-paper';
const options = {
    name: '_blank',
    specs: [
        'fullscreen=yes',
        'titlebar=yes',
        'scrollbars=yes'
    ],
    styles: [
        '/logs_print.css'
    ],
    timeout: 1000, // default timeout before the print window appears
    autoClose: true, // if false, the window will not close after printing
    windowTitle: window.document.title, // override the window title
}
Vue.use(VueHtmlToPaper, options);

//Vue Filters
Vue.filter('imgpath', function (value) {

    return "dist/images/news/" + value;
})
Vue.filter('casenum_format', function (value) {
    if (!value) return ''
    value = value.toString();
    return value.substring(0, 2) + '-' + value.substring(4, value.length);
})

Vue.filter('shortDate', function (value) {
    if (!value) return '';
    return Vue.prototype.$moment(value).format("MM/DD/YYYY");
})
//datePicker
Vue.filter('datePicker', function (value) {
    if (!value) return '';

    return Vue.prototype.$moment(value).format("YYYY-MM-DD");
})


new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
